@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('../fonts/AktivGrotesk/AktivGrotesk-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('../fonts/AktivGrotesk/AktivGrotesk-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('../fonts/AktivGrotesk/AktivGrotesk-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Aktiv Grotesk';
	src: url('../fonts/AktivGrotesk/AktivGrotesk-XBold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}
