/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: 'Aktiv Grotesk', Arial, sans-serif;

	--invalidColor: red;
	--primaryColor: var(--primary-maroon);

	--off-white: #F5F5F5;
	--platform-background-white: #fff;
	--text-light: #fff;
	--tertiary-white: #fff;
	--primary-maroon: #951C2F;
	--secondary-light-black: #1E1E1E;
	--secondary-black: #000;
	--secondary-cream: #F5DDD2;
	--secondary-red: #C22033;
	--secondary-pink: #E88C99;
	--mens-bulgaria: #009000;

}

html, body {
	font-family: var(--fontFamilyBase);
	min-height: 100%;
	height: 100%;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

body {
	background-color: var(--platform-background-white);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	font-size: 16px;
	line-height: 20px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
	font-weight: 900;
}

h1 {
	font-size: 40px;
	line-height: 48px;
}

h2 {
    font-size: 26px;
	line-height: 36px;
}

h3 {
    font-size: 20px;
	line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

a {
	color: var(--primary-maroon);
}

sub {
	vertical-align: sub;
}
